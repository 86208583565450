import { Link } from 'react-router-dom';
import { buzzmeWebsite, qqpayPostmanDocsLink } from '../../constants/defaults';
import Button from '../Button';

import { IoMdCheckmarkCircle } from 'react-icons/io';

export default function FourthSection() {
  const cardFeatures = [
    'Dashboard Access',
    'Instant Settlement',
    'Instant Reconciliation ',
    'No Chargeback issues',
    'Preventive Fraud & Compliance',
    'QR Codes & NFC Solutions',
  ];

  return (
    <div className='pb-4 md:pb-10'>
      <div className='max-w-[70rem] text-jakarta mx-auto w-full py-5 md:py-10 general-padding'>
        <div className='md:mt-20'>
          <div className='text-sm my-1 text-primary'>CASE STUDY</div>
          <Link
            to={buzzmeWebsite}
            target='_blank'
            className='text-xl md:text-2xl font-bold underline text-secondary'
          >
            BUZZME MOBILE APP
          </Link>

          <p className=' max-w-[22rem] mt-2 md:mt-5 text-sm font-normal'>
            Buzzme is a contactless payment application developed to make
            in-person payments experiences seamless & simpler in a world where
            we all have supercomputers in our pocket
          </p>

          <div className='my-10'>
            <img
              src='/buzzme.png'
              alt='QQpay Landing page'
              className=' mx-auto w-full '
            />
          </div>
        </div>

        <div className='my-12 md:my-20 '>
          <div className='text-center'>
            <h5 className='text-xl md:text-2xl text-black font-semibold'>
              Ready to Get Started?
            </h5>

            <p className=' mt-2 md:mt-4 text-sm font-normal'>
              Pricing that suits your business scale
            </p>
          </div>

          <div className='my-7 md:my-14'>
            <div className='text-xs text-black max-w-[18rem] mx-auto bg-grey rounded-xl py-7 px-3'>
              <div className='flex justify-between items-center'>
                <div className='flex space-x-2 items-center'>
                  <img
                    src='/crown.png'
                    alt='QQpay Landing page'
                    className='w-5 '
                  />
                  <div className='text-xl font-normal'>Per Txn</div>
                </div>
                <div className='px-2 flex flex-col justify-center py-1 text-xs rounded-3xl bg-black text-white'>
                  <span>Popular</span>
                </div>
              </div>

              <p className='my-2 text-dark-grey'>
                Perfect plan for growth businesses
              </p>

              <div className='my-4'>
                <span className='font-semibold text-2xl'>₦2.75 </span>
                <span className='text-dark-grey'>/transaction</span>
              </div>

              <p className='opacity-80'>
                For growth focused organisations leveraging contactless payments
                for scale
              </p>

              <div className='mt-5 grid gap-3'>
                {cardFeatures.map((feature, idx) => {
                  return (
                    <div key={idx} className='flex items-center space-x-3'>
                      <IoMdCheckmarkCircle className='text-lg text-primary' />

                      <div>{feature}</div>
                    </div>
                  );
                })}
              </div>

              <div className='mt-8 flex justify-center'>
                <Link to={qqpayPostmanDocsLink} target='_blank'>
                  <Button className='bg-primary text-white text-sm underline'>
                    Get started
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
