import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { qqpayPostmanDocsLink } from '../../constants/defaults';
import Button from '../Button';

export default function ThirdSection() {
  return (
    <div className='bg-primary text-white pb-7 md:pb-14'>
      <div className='max-w-[70rem] mx-auto w-full py-10 general-padding'>
        <div className='w-full mt-5 md:mt-14 text-center max-w-[50rem] mx-auto'>
          <h3 className='text-2xl xmobile:text-3xl mobile:text-4xl font-bold'>
            Building the global infrastructure <br /> for contactless payments
          </h3>

          <div className='h-[400px] tablet:h-[450px] w-full md:w-[80%] mx-auto laptop:h-[500px] mt-10 my-6'>
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url='https://youtu.be/Pr4SHM-iJe4'
              controls={true}
              playsinline
            />
          </div>
        </div>

        <div className='mt-12 md:mt-20'>
          <div className='text-sm my-5 md:my-8 text-center'>HOW IT WORKS?</div>

          <div className=' grid place-items-center grid-cols-1 tablet:grid-cols-2 gap-8 xmd:gap-12 my-4 md:my-10'>
            <img
              src='/dashboard.png'
              alt='QQpay Landing page'
              className=' mx-auto w-[32rem] tablet:w-full max-tablet:order-last '
            />

            <div className='grid gap-3 xmd:gap-5'>
              <h3 className='text-2xl smLaptop:text-3xl leading-tight  font-bold'>
                Few EasyPeasy Steps and Done
              </h3>

              <p className='max-mobile:text-sm max-w-[40rem] font-normal   mx-auto'>
                In just few easy steps, you are all set to deploy QR & NFC
                Solutions at scale
              </p>
              <img
                src='/steps.png'
                alt='QQpay Landing page'
                className=' mx-auto w-[25rem] tablet:w-full '
              />
            </div>
          </div>
        </div>

        <div className='mt-10 md:mt-16 flex justify-center'>
          <Link to={qqpayPostmanDocsLink} target='_blank'>
            <Button className='bg-white text-primary text-sm underline'>
              Get our API's
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
