import {
  AiOutlineGooglePlus,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { navLinks } from './Navbar';

export default function Footer() {
  return (
    <div className='w-full py-5 md:py-12 bg-primary text-white'>
      <div className='max-w-[80rem] mx-auto w-full general-padding'>
        <div className='flex font-medium max-md:flex-col max-md:justify-start max-md:space-y-4 md:space-x-9 md:items-center uppercase max-mobile:text-xs text-sm my-8'>
          {navLinks.map((link, idx) => {
            return (
              <Link
                key={idx}
                to={link.url || '/'}
                target={link.url && `_blank`}
                className='hover:opacity-75'
              >
                {link.label}
              </Link>
            );
          })}
        </div>

        <hr className='border-white/30' />

        <div className='mt-10 mb-12 md:mb-24 flex  max-md:flex-col justify-between'>
          <div className='flex max-md:flex-col md:space-x-8'>
            <div>
              <img
                src='/whiteLogo.png'
                alt='El-buk logo'
                className='w-32 mobile:w-44 md:w-32 tablet:w-40 laptop:w-50 '
              />
            </div>
            <p className='max-w-[450px] md:max-w-[320px] max-md:mt-4 laptop:max-w-[400px] max-mobile:text-sm max-md:text-base max-tablet:text-sm font-light'>
              QQPay Wallet Services are provided by Paystack and it’s affiliate
              financial service partners.
            </p>
          </div>

          <div className='flex min-[940px]:space-x-8 max-[940px]:space-y-5 max-[940px]:flex-col  max-md:mt-5  md:items-center'>
            <div className='flex max-md:flex-col  font-light max-md:space-y-3 md:space-x-10 max-mobile:text-sm max-md:text-base max-tablet:text-sm'>
              <Link to={'/'}>Privacy Policy</Link>
              <Link to={'/'}>Terms </Link>
            </div>

            <div className='flex items-center space-x-5'>
              <Link
                to={'https://twitter.com/hi_qqpay'}
                target='_blank'
                className='hover:opacity-75'
              >
                <AiOutlineTwitter className='text-xl' />
              </Link>
              <Link
                to={'https://www.instagram.com/hi_qqpay/'}
                className='hover:opacity-75'
                target='_blank'
              >
                <AiOutlineInstagram className='text-xl' />
              </Link>
              <Link
                to={'mailto:hi.qqpay@gmail.com'}
                target='_blank'
                className='hover:opacity-75'
              >
                <AiOutlineGooglePlus className='text-2xl' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
