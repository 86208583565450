import HomePage from '../pages/homepage'

export const routes = [
	{
		path: '/',
		element: <HomePage />,
	},
	// {
	// 	path: 'contact',
	// 	element: <GetInTouchPage />,
	// },
	// {
	// 	path: 'portfolio',
	// 	element: <PortfolioPage />,
	// },
	// {
	// 	path: 'portfolio/:id',
	// 	element: <ProjectDetails />,
	// },
	// {
	// 	path: 'services',
	// 	element: <OurServicesPage />,
	// },
]
