import { Link } from 'react-router-dom';
import Button from '../Button';
import {
  qqpayAppointmentLink,
  qqpayPostmanDocsLink,
} from '../../constants/defaults';

export default function Hero() {
  return (
    <div className=' bg-primary text-white py-2 mobile:py-4 pb-14  md:!pb-28 flex flex-col justify-between tablet:py-7'>
      <div className='max-w-[80rem] mx-auto w-full general-padding flex max-md:flex-col items-center gap-4 md:gap-8'>
        <div className='flex-1'>
          <div className='max-md:text-center'>
            <h3 className='text-3xl mobile:text-4xl xmd:text-5xl smLaptop:text-7xl leading-tight font-medium'>
              Scale easily with <br className='max-md:hidden' />
              contactless <br className='max-xmd:hidden' /> payments
            </h3>

            <div className='max-smLaptop:text-sm my-5 md:my-10 mobile:max-w-[70%] max-md:mx-auto md:max-w-[90%] font-light'>
              With our APIs and interoperable ecosystem, we help product led
              organisations embed seamless & functional QR & NFC Payment
              Solutions in their products.
            </div>
          </div>

          <div className='hidden md:flex gap-3 smLaptop:gap-5 items-center mt-4'>
            <Link to={qqpayPostmanDocsLink} target='_blank'>
              <Button className='bg-white text-primary text-sm underline'>
                Get our API's
              </Button>
            </Link>

            <Link to={qqpayAppointmentLink} target='_blank'>
              <Button className='underline text-sm border border-white'>
                Speak to Us
              </Button>
            </Link>
          </div>
        </div>

        <div className='md:flex-1'>
          <img
            src='/hero-img.png'
            alt='QQPAY hero section'
            className='max-mobile:w-[17rem] max-md:w-[20rem] ml-auto'
          />
        </div>

        <div className='md:hidden flex gap-3 smLaptop:gap-5 items-center mt-4'>
          <Link to={qqpayPostmanDocsLink} target='_blank'>
            <Button className='bg-white text-primary text-sm underline'>
              Get our API's
            </Button>
          </Link>

          <Link to={qqpayAppointmentLink} target='_blank'>
            <Button className='underline text-sm border border-white'>
              Speak to Us
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
