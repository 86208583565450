export default function SupportPage() {
	return (
		<div className='text-center'>
			<h1 className=' text-3xl font-bold'>
				Click the button at the bottom to chat
			</h1>
			<p>If it hasn't shown up yet, it might take a minute. Please wait.</p>
		</div>
	)
}
