import React, { ReactNode, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  const combinedClassName = `h-10 px-6 w-fit max-mobile:text-xs mobile:w-48 smLaptop:w-64 cursor-pointer rounded-3xl ${
    className || ''
  }`;
  return (
    <button className={combinedClassName} {...props}>
      {children}
    </button>
  );
};

export default Button;
