// import {
//   AiOutlineTwitter,
//   AiOutlineGooglePlus,
//   AiOutlineInstagram,
// } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useEffect, useState } from 'react';
import { qqpayPostmanDocsLink } from '../../constants/defaults';

export const navLinks = [
  {
    label: 'Download',
    url: '',
  },
  {
    label: `API's`,
    url: qqpayPostmanDocsLink,
  },
  {
    label: 'Support',
    url: 'mailto:hi.qqpay@gmail.com',
  },
  {
    label: 'Blog',
    url: 'https://medium.com/@hi.qqpay',
  },
];

export default function Navbar() {
  // const location = useLocation()

  const [sideMenu, setSideMenu] = useState(false);

  useEffect(() => {
    // Add/remove class to body when sideMenu is opened/closed
    const body = document.body;
    if (sideMenu) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }

    // Cleanup on component unmount
    return () => {
      body.classList.remove('no-scroll');
    };
  }, [sideMenu]);

  return (
    <div className='w-full h-fit sticky top-0 bg-primary z-50  py-3'>
      <div className='max-w-[80rem] mx-auto w-full general-padding'>
        <div className='flex items-center w-full justify-between py-2 '>
          <div className='max-width w-full text-white flex items-center justify-between'>
            <img src='/logo.png' alt='El-buk logo' className='w-24 md:w-32 ' />

            <div className='hidden tablet:flex items-center space-x-7'>
              {navLinks.map((link, idx) => {
                return (
                  <Link
                    key={idx}
                    to={link.url || '/'}
                    target={link.url && `_blank`}
                    className={`font-light cursor-pointer hover:opacity-75`}
                  >
                    {link.label}
                  </Link>
                );
              })}
            </div>

            {/* <div className='hidden tablet:flex items-center space-x-5'>
              <Link
                to={'https://twitter.com/hi_qqpay'}
                target='_blank'
                className='hover:opacity-75'
              >
                <AiOutlineTwitter className='text-xl' />
              </Link>
              <Link
                to={'https://www.instagram.com/hi_qqpay/'}
                className='hover:opacity-75'
                target='_blank'
              >
                <AiOutlineInstagram className='text-xl' />
              </Link>
              <Link
                to={'mailto:hi.qqpay@gmail.com'}
                target='_blank'
                className='hover:opacity-75'
              >
                <AiOutlineGooglePlus className='text-2xl' />
              </Link>
            </div> */}

            <GiHamburgerMenu
              onClick={() => setSideMenu(true)}
              className='text-2xl tablet:hidden block text-white'
            />
          </div>
        </div>

        {sideMenu && (
          <div
            onClick={() => setSideMenu(false)}
            className='bg-black/30 fixed h-full left-0 top-0 w-full '
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className='h-fit pt-2 pb-4 bg-white px-3  absolute right-[2px] top-16 rounded-l'
            >
              <div
                className='flex justify-end w-full  mb-[6px]'
                onClick={() => setSideMenu(false)}
              >
                <MdClose className='text-xl -mr-2' />
              </div>
              <div className='grid gap-3 pr-3'>
                {navLinks.map((link, idx) => {
                  return (
                    <Link
                      key={idx}
                      to={link.url || '/'}
                      target={link.url && `_blank`}
                      onClick={() => setSideMenu(false)}
                      className={`mobile:text-lg pb-1 px-2 font-light ${
                        navLinks?.length - 1 !== idx && 'border-b'
                      }  hover:text-primary `}
                    >
                      {link.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
