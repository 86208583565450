import { IoWalletOutline } from 'react-icons/io5';
import { HiOutlineDocumentText } from 'react-icons/hi2';
import { TiCreditCard } from 'react-icons/ti';

export default function SecondSection() {
  return (
    <div className='mobile:pb-5 md:pb-10'>
      <div className='mt-4 max-w-[70rem] general-padding  mx-auto'>
        <div className='mx-auto md:w-[40rem] text-center'>
          <div className='text-primary text-sm mb-1'>WHY QQPay?</div>
          <h3 className='text-2xl xmobile:text-3xl mobile:text-4xl  text-secondary leading-tight  font-bold'>
            Join the exclusive network of innovative payment organisations.
          </h3>

          <div className='my-5'>
            <p className=' max-mobile:text-sm max-w-[40rem] font-normal opacity-60  mx-auto'>
              Our platform provides the complete infrastructure and tools needed
              for African businesses to build, embed and launch contactless
              payment solution into their products for seamless In-person
              payment experiences.
            </p>
          </div>
        </div>

        <div className='mt-12'>
          <div className=' grid grid-cols-1 lgTablet:grid-cols-2 mt-10 md:mt-20 mb-10'>
            <div className='flex max-lgTablet:order-last max-lgTablet:space-y-8 max-lgTablet:mt-8 flex-col items-start px-0 md:px-16 pb-5 space-y-6 justify-between'>
              <div className='flex space-x-2 mobile:space-x-4'>
                <div className='rounded-lg flex flex-col justify-center items-center bg-primary p-[6px] mobile:p-2 h-fit text-white'>
                  <IoWalletOutline className='w-4 mobile:w-5 h-4 mobile:h-5' />
                </div>
                <div>
                  <p className=' -mt-1 font-medium text-secondary'>
                    Our interoperable ecosystem
                  </p>
                  <div className='text-sm'>
                    Leverage our APIs to connect seamlessly with other payment
                    providers within our network to reach scale.
                  </div>
                </div>
              </div>

              <div className='flex space-x-2 mobile:space-x-4'>
                <div className='rounded-lg flex flex-col justify-center items-center bg-primary p-[6px] mobile:p-2 h-fit text-white'>
                  <HiOutlineDocumentText className='w-4 mobile:w-5 h-4 mobile:h-5' />
                </div>
                <div>
                  <p className=' -mt-1 font-medium text-secondary'>
                    Digital Wallet Framework
                  </p>
                  <div className='text-sm'>
                    Experience less operational hassles with our digital wallet
                    framework that runs in parallel for 100% uptime.
                  </div>
                </div>
              </div>

              <div className='flex space-x-2 mobile:space-x-4'>
                <div className='rounded-lg flex flex-col justify-center items-center bg-primary p-[6px] mobile:p-2 h-fit text-white'>
                  <TiCreditCard className='w-4 mobile:w-5 h-4 mobile:h-5' />
                </div>
                <div>
                  <p className=' -mt-1 font-medium text-secondary'>
                    Improved In-person payments
                  </p>
                  <div className='text-sm'>
                    We improve payment experiences for your users enabling them
                    to simply scan a QR code or NFC of another user with any
                    other payment provider within the network.
                  </div>
                </div>
              </div>
            </div>

            <img
              src='/txn-history.png'
              alt='QQpay'
              className=' mx-auto w-[30rem] lgTablet:w-full '
            />
          </div>
        </div>

        <div className='mt-4 mobile:mt-8 md:mt-14'>
          <div className='max-tablet:text-center mt-8 mb-14 flex max-tablet:grid max-tablet:grid-cols-1 tablet:space-x-10'>
            <div className='w-full'>
              <h3 className='text-xl xmobile:text-2xl mobile:text-3xl md:text-4xl smLaptop:text-5xl text-secondary font-bold'>
                Quick Quick & Simpler In-person payment experiences
              </h3>
            </div>

            <div className='w-full md:mt-5'>
              <p className='max-mobile:text-sm opacity-60 my-4'>
                QQPay leverages wallet & contactless payment technologies (QR
                Codes & NFC) to empower product led organizations improve the
                in-person payments experience for users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
