import Hero from '../components/homepage/Hero';
import SecondSection from '../components/homepage/SecondSection';
import ThirdSection from '../components/homepage/ThirdSection';
import FourthSection from '../components/homepage/FourthSection';

export default function Homepage() {
  return (
    <div className='grid gap-5 mobile:gap-8'>
      <Hero />
      <SecondSection />

      <ThirdSection />
      <FourthSection />
    </div>
  );
}
